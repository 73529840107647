import { queryOptions, useQuery } from "@tanstack/react-query";

export const getPlateModal = () => {
  return queryOptions({
    queryKey: ["plateModal"],
    initialData: {
      hasLoaded: false,
    },
  });
};

export const usePlateModal = () => {
  return useQuery({
    ...getPlateModal(),
  });
};
