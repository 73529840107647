import { useEffect } from "react";

import { useTheme } from "./useTheme";

// hook used to dynamically load new color scheme
export const useManageTheme = (data) => {
  const { setTheme } = useTheme();

  // only change theme when theme object reference changes.
  useEffect(() => {
    const root = document.documentElement;

    if (data?.skin?.theme) {
      const {
        skin: { theme },
      } = data;
      // loop through each theme property & thereby override css color variables
      Object.keys(theme).forEach((key) => {
        root.style.setProperty(`--${key}-color`, theme[key]);
      });
      // save current theme object to context such that other theme dependent source may ingest (ie materialUI)
      setTheme(theme);
    }
  }, [data?.skin?.theme]);
};
