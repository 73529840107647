import { queryOptions, useQuery } from "@tanstack/react-query";
import { postCheckout } from "../services/checkout";

export const getCheckout = (rateId, noStateCallback) => {
  return queryOptions({
    queryKey: ["checkout"],
    queryFn: async () => {
      const response = await postCheckout(rateId, noStateCallback);
      if (!response.success) throw new Error(response.failReason);
      return response;
    },
    initialData: {
      rates: [],
    },
    retry: false,
  });
};

export const useCheckout = (rateId, noStateCallback) => {
  return useQuery({
    ...getCheckout(rateId, noStateCallback),
  });
};
