const PathConstants = {
  WELCOME: "/welcome",
  CONTACT_INFO: "/contact-info",
  PARKING_RATES: "/parking-rates",
  CHECKOUT: "/checkout",
  CHECKOUT_COMPLETE: "/checkout-complete",
  PAYMENT: "/payment",
  STRIPE_PAYMENT: "/_payment",
  TERMS_OF_USE: "/terms-of-use",
  PRIVACY_POLICY: "/privacy-policy",
  PREPAID_TICKET: "/prepaid-ticket",
  RECEIPT: "/receipt",
  RECEIPT_LINK: "/receipt-link",
  ERROR: "/error",
  LOT_MISMATCH: "/lot-mismatch",
  LOT_MISMATCH_PREPAID: "/lot-mismatch-prepaid",
  SESSION_EXPIRED: "/session-expired",
};

export default PathConstants;
