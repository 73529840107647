import { useState } from "react";
import { Link } from "react-router-dom";
import PathConstants from "../../../routes/pathConstants";
import Modal from "../Modal";
import { Button, Divider } from "@mui/material";
import Phone from "../../../assets/Icons/Phone";
import Worker from "../../../assets/Icons/Worker";
import Mail from "../../../assets/Icons/Mail";
import { normalizePhoneNumber } from "../../../utils/formatPhone";
import PropTypes from "prop-types";
import versionNumber from "../../../constants/versionNumber";

const PrivacyAndTerms = (props) => {
  const {
    showHelp,
    onCloseHelp,
    withoutTerms,
    welcomeData,
    hideHelpButton,
    isLoading,
  } = props;
  const [helpModalOpen, setHelpModalOpen] = useState(false);

  const handleCloseModal = () => {
    setHelpModalOpen(false);
    if (showHelp) {
      onCloseHelp();
    }
  };

  // TODO move help button into Pagelayout

  return (
    <div className="mt-12 justify-center bottom-6">
      <Modal
        open={helpModalOpen || showHelp}
        onClose={() => handleCloseModal(false)}
        withClose
        title="Help"
      >
        <div>
          <span className="mx-auto">
            <div
              className="mt-8"
              style={{ marginLeft: "-1.5rem", marginRight: "-1.5rem" }}
            >
              <Divider />
            </div>
            <div>
              <div className="flex flex-row mt-6">
                <div className="min-w-6 flex flex-row items-center">
                  <Worker />
                </div>
                <div className="ml-4 text-lg leading-6">
                  Seek out a parking attendant for assistance.
                </div>
              </div>
              <div className="flex flex-row mt-6">
                <div className="min-w-6 flex flex-row items-center">
                  <Mail />
                </div>
                <div className="ml-4 leading-6 flex flex-col">
                  <div className="text-lg">Email</div>
                  <a
                    className="text-sm wrap-word"
                    href={`mailto: ${
                      welcomeData?.skin?.supportEmail ??
                      "info@interstateparking.com"
                    }`}
                  >
                    {welcomeData?.skin?.supportEmail ??
                      "info@interstateparking.com"}
                  </a>
                </div>
              </div>
              {welcomeData?.skin?.supportPhone && (
                <>
                  <div className="flex flex-row mt-6">
                    <div className="min-w-6 flex flex-row items-center">
                      <Phone />
                    </div>
                    <div className="ml-4 text-lg leading-6">
                      For immediate assistance please call{" "}
                      <a href={`tel:+1${welcomeData.skin.supportPhone}`}>
                        {normalizePhoneNumber(welcomeData.skin.supportPhone)}
                      </a>
                    </div>
                  </div>
                  <div
                    className="mt-4"
                    style={{ marginLeft: "-1.5rem", marginRight: "-1.5rem" }}
                  >
                    <Divider />
                  </div>
                  <div>
                    <div className="mt-4">
                      <Button
                        size="medium"
                        variant="contained"
                        onClick={() => {}}
                        color="primary"
                        style={{
                          fontWeight: 700,
                          height: "2.5rem",
                          width: "8rem",
                          float: "right",
                        }}
                        href={`tel:+1${welcomeData.skin.supportPhone}`}
                        disabled={false}
                        startIcon={<Phone fill="var(--neutral-white-color)" />}
                      >
                        Call Us
                      </Button>
                    </div>
                  </div>
                </>
              )}
            </div>
          </span>
        </div>
      </Modal>
      {/* Hide footer while loading spinner is up */}
      {!isLoading && (
        <>
          {!withoutTerms && (
            <>
              <Link
                to={PathConstants.PRIVACY_POLICY}
                className="mr-4 text-sm underline font-bold text-secondary"
              >
                Privacy Policy
              </Link>
              |
              <Link
                to={PathConstants.TERMS_OF_USE}
                className="ml-4 text-sm underline font-bold text-secondary"
              >
                Terms of Use
              </Link>
            </>
          )}
          <div
            className="my-4 font-bold"
            style={{ lineHeight: "14.88px", fontSize: "12px" }}
          >
            &copy;&nbsp;2024 IPC Technology Group, LLC.
            <br />
            All rights reserved.
          </div>
          <div
            className="my-4 font-semibold"
            style={{
              fontSize: "9px",
              color: "#8B8984",
              marginBottom: ".8rem",
            }}
          >
            Version {versionNumber}
          </div>
        </>
      )}

      {!hideHelpButton ? (
        <div
          className="fixed right-4 bottom-6"
          style={{
            width: "3.5rem",
            height: "3.5rem",
            borderRadius: "1.75rem",
            backgroundColor: "var(--secondary-color)",
            color: "white",
            paddingTop: ".4rem",
            fontSize: "30px",
          }}
          onClick={() => setHelpModalOpen(true)}
        >
          ?
        </div>
      ) : null}
    </div>
  );
};

PrivacyAndTerms.propTypes = {
  showHelp: PropTypes.bool,
  onCloseHelp: PropTypes.func,
  withoutTerms: PropTypes.bool,
  welcomeData: PropTypes.object,
  hideHelpButton: PropTypes.bool,
  helpModalOpen: PropTypes.bool,
  setHelpModalOpen: PropTypes.func,
  handleCloseModal: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default PrivacyAndTerms;
