import { Button, Divider, InputAdornment } from "@mui/material";
import { useEffect, useState } from "react";

import ErrorModal from "../../components/Layout/ErrorModal";
import FlagUs from "../../assets/Icons/FlagUs";
import Mail from "../../assets/Icons/Mail";
import Message from "../../assets/Icons/Message";
import Modal from "../../components/Layout/Modal";
import PageLayout from "../../components/Layout/PageLayout";
import PathConstants from "../../routes/pathConstants";
import Receipt from "../../assets/Icons/Receipt";
import TextField from "../../components/Inputs/TextField";
import cacheKeys from "../../constants/cache";
import { normalizePhoneNumber } from "../../utils/formatPhone";
import { postEmail } from "../../services/email";
import { postReceipt } from "../../services/receipt";
import { postText } from "../../services/text";
import { useContactInfo } from "../../hooks/contact-info";
import { useNavigate } from "react-router-dom";
import { usePlateModal } from "../../hooks/plate-modal";
import { useQueryClient } from "@tanstack/react-query";
import validateEmail from "../../utils/validate-email";
import validatePhoneNumber from "../../utils/validate-phone";

const CheckoutComplete = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [receiptIsLoading, setReceiptIsLoading] = useState(false);
  const [sentAlternateReceipt, setSentAlternatReceipt] = useState(false);
  const [sendPhoneReceipt, setSendPhoneReceipt] = useState(false);
  const [sendEmailReceipt, setSendEmailReceipt] = useState(false);
  const [sendReceiptIsLoading, setSendReceiptIsLoading] = useState(false);
  const [errorReason, setErrorReason] = useState(null);
  const [isRedirecting, setIsRedirecting] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");

  const { data: contactInfo } = useContactInfo();
  const rateData = queryClient.getQueryData(["rate"]);
  const prepaidData = queryClient.getQueryData(["prepaid"]);

  const [emailWarning, setEmailWarning] = useState("");
  const [phoneWarning, setPhoneWarning] = useState("");

  const { data: plateModalData } = usePlateModal();

  const qrCodeCache = window.sessionStorage.getItem(cacheKeys.qrCode);

  useEffect(() => {
    if (!qrCodeCache) {
      setIsRedirecting(true);
      window.location.replace(PathConstants.ERROR);
    }

    if ((!rateData && !prepaidData) || contactInfo?.licensePlate?.length < 2) {
      setIsRedirecting(true);
      window.location.replace(`${PathConstants.WELCOME}?qr=${qrCodeCache}`);
    }
  }, [qrCodeCache, rateData, prepaidData, contactInfo]);

  useEffect(() => {
    if (!sendEmailReceipt || email === "") return;
    setEmailWarning(
      validateEmail(email) ? "" : "Please enter a valid email address."
    );
  }, [sendEmailReceipt, email]);

  useEffect(() => {
    if (!sendPhoneReceipt || phoneNumber === "") return;
    setPhoneWarning(
      validatePhoneNumber(phoneNumber)
        ? ""
        : "Please enter a valid mobile phone number."
    );
  }, [sendPhoneReceipt, phoneNumber]);

  useEffect(() => {
    if (plateModalData) {
      plateModalData.hasLoaded = false;
    }
  }, [plateModalData]);

  //Yes, the handler removes itself. We only want this effect on this page.
  /* const navigateToWelcomeOnBackHandler = () => {
    window.removeEventListener("popstate", navigateToWelcomeOnBackHandler);
    navigate(`${PathConstants.WELCOME}?qr=${qrCodeCache}`);
  }; */

  /* useEffect(() => {
    //Reroute the browser's back button to /welcome
    window.addEventListener("popstate", navigateToWelcomeOnBackHandler);
  }); */

  // TODO Streamline this and get receipt id once

  const handleOnClickReceipt = async () => {
    setReceiptIsLoading(true);
    const prepaidData = queryClient.getQueryData(["prepaid"]);
    const plateData = queryClient.getQueryData(["plate"]);
    const payData = queryClient.getQueryData(["pay"]);
    let res;

    try {
      if (prepaidData?.receiptID) {
        res = await postReceipt(prepaidData?.receiptID);
      } else if (plateData?.plateData?.alreadyPaidData?.receiptID) {
        res = await postReceipt(plateData?.alreadyPaidData?.receiptID);
      } else if (payData?.receiptID) {
        res = await postReceipt(payData?.receiptID);
      }
    } catch (error) {
      setErrorReason("unknownSystemError");
    }

    queryClient.setQueryData(["receipt"], res);
    setReceiptIsLoading(false);
    navigate(PathConstants.RECEIPT);
  };

  const handleSendReceipt = async () => {
    const prepaidData = queryClient.getQueryData(["prepaid"]);
    const plateData = queryClient.getQueryData(["plate"]);
    const payData = queryClient.getQueryData(["pay"]);
    let receiptId = "";
    if (prepaidData?.receiptID) {
      receiptId = prepaidData?.receiptID;
    } else if (plateData?.plateData?.alreadyPaidData?.receiptID) {
      receiptId = plateData?.plateData?.alreadyPaidData?.receiptID;
    } else if (payData?.receiptID) {
      receiptId = payData?.receiptID;
    }

    try {
      if (sendPhoneReceipt) {
        setSendReceiptIsLoading(true);
        await postText(receiptId, phoneNumber);
        setSentAlternatReceipt(true);
      }
      if (sendEmailReceipt) {
        setSendReceiptIsLoading(true);
        await postEmail(receiptId, email);
        setSentAlternatReceipt(true);
      }
    } catch (error) {
      setErrorReason("unknownSystemError");
    } finally {
      setSendReceiptIsLoading(false);
      setSendEmailReceipt(false);
      setSendPhoneReceipt(false);
    }
  };

  const handleOnChangePhone = (e) => {
    setPhoneNumber(normalizePhoneNumber(e.target.value));
  };
  const handleOnChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const emailReceipt = contactInfo.showEmail && !prepaidData;
  const phoneReceipt = !contactInfo.showEmail && !prepaidData;

  const handleOnClickFinish = () => {
    // Invalidate most caches before returning to welcome page
    queryClient.removeQueries({ queryKey: ["checkout"] });
    queryClient.removeQueries({ queryKey: ["convenience"] });
    queryClient.removeQueries({ queryKey: ["lotmismatch"] });
    queryClient.removeQueries({ queryKey: ["parkingPass"] });
    queryClient.removeQueries({ queryKey: ["plate"] });
    queryClient.removeQueries({ queryKey: ["prepaid"] });
    queryClient.removeQueries({ queryKey: ["rateselect"] });
    queryClient.removeQueries({ queryKey: ["rates"] });
    navigate(`${PathConstants.WELCOME}?qr=${qrCodeCache}`);
  };

  const goodToSend =
    (sendPhoneReceipt && validatePhoneNumber(phoneNumber)) ||
    (sendEmailReceipt && validateEmail(email));

  //Do not render anything if we are redirecting
  return isRedirecting ? null : (
    <PageLayout
      title={"Checkout Complete!"}
      isLoading={receiptIsLoading || sendReceiptIsLoading}
    >
      <ErrorModal
        failReason={errorReason}
        open={!!errorReason}
        onClose={() => setErrorReason(null)}
      />
      {!rateData?.useRapidCheckout && (
        <div className="pt-2  mx-5">
          Your parking pass has been activated. You&apos;re all set for
          today&apos;s event!
        </div>
      )}
      {rateData?.useRapidCheckout && (
        <div className="pt-2  mx-5">
          No payment needed for today&apos;s event &mdash; you&apos;re all set.
        </div>
      )}
      {
        <>
          {(emailReceipt || sentAlternateReceipt) && (
            <div className="pt-6 mx-5">
              Your receipt has been mailed to{" "}
              <span className="font-bold">{contactInfo.email || email}</span>
            </div>
          )}
          {emailReceipt && !sentAlternateReceipt && (
            <div className="mx-2 my-5">
              <Button
                size="large"
                variant="contained"
                color="secondary"
                onClick={() => setSendPhoneReceipt(true)}
                style={{ fontWeight: 700, width: "100%" }}
                startIcon={<Message fill="var(--neutral-white-color)" />}
              >
                Send Receipt via Text
              </Button>
            </div>
          )}
          {(phoneReceipt || sentAlternateReceipt) && (
            <div className="pt-6 mx-5">
              Your receipt has been texted to <br></br>
              <span className="font-bold">
                {contactInfo.phone || phoneNumber}
              </span>
            </div>
          )}
          {phoneReceipt && !sentAlternateReceipt && (
            <div className="mx-2 my-5">
              <Button
                size="large"
                variant="contained"
                color="secondary"
                onClick={() => setSendEmailReceipt(true)}
                style={{ fontWeight: 700, width: "100%" }}
                startIcon={<Mail fill="var(--neutral-white-color)" />}
              >
                Send Receipt via Email
              </Button>
            </div>
          )}
          <div className="mx-2 my-5">
            <Button
              size="large"
              variant="contained"
              color="primary"
              onClick={handleOnClickReceipt}
              style={{ fontWeight: 700, width: "100%" }}
              startIcon={<Receipt />}
            >
              View Receipt
            </Button>
          </div>
        </>
      }
      <div className="mx-2 my-5">
        <Button
          size="large"
          color="secondary"
          onClick={() => handleOnClickFinish()}
          style={{ fontWeight: 700, width: "100%" }}
        >
          <span className="underline">Purchase or activate another pass</span>
        </Button>
      </div>
      <Modal
        open={sendPhoneReceipt || sendEmailReceipt}
        withClose
        title={`Send receipt via ${sendPhoneReceipt ? "text" : "email"}`}
        onClose={() => {
          setSendPhoneReceipt(false);
          setSendEmailReceipt(false);
        }}
      >
        <div className="mt-8">
          <div style={{ marginLeft: "-1.5rem", marginRight: "-1.5rem" }}>
            <Divider />
          </div>
          {sendPhoneReceipt && (
            <div className="my-8">
              <TextField
                value={phoneNumber}
                name="phone"
                type="tel"
                autocomplete="mobile"
                onChange={(e) => handleOnChangePhone(e)}
                label="Phone Number"
                placeholder="(123) 456-7890"
                error={phoneWarning}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FlagUs />
                    </InputAdornment>
                  ),
                }}
              />
            </div>
          )}
          {sendEmailReceipt && (
            <div className="my-5">
              <TextField
                value={email}
                onChange={(e) => handleOnChangeEmail(e)}
                type="email"
                label="Email"
                placeholder="john.doe@example.com"
                error={emailWarning}
              />
            </div>
          )}
        </div>
        <div style={{ marginLeft: "-1.5rem", marginRight: "-1.5rem" }}>
          <Divider />
        </div>
        <div>
          <div className="mt-4 flex justify-end">
            <Button
              size="medium"
              variant="contained"
              onClick={() => handleSendReceipt()}
              color="primary"
              disabled={!goodToSend}
              style={{
                fontWeight: 700,
                height: "2.5rem",
                width: "45%",
              }}
            >
              Send Receipt
            </Button>
          </div>
        </div>
      </Modal>
    </PageLayout>
  );
};

export default CheckoutComplete;
