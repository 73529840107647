import { queryOptions, useQuery } from "@tanstack/react-query";

export const getReceipt = () => {
  return queryOptions({
    queryKey: ["receipt"],
    initialData: {
      receipt: {},
    },
  });
};

export const useReceipt = () => {
  return useQuery({
    ...getReceipt(),
  });
};
