import { TextField as TextFieldMaterial } from "@mui/material";
import PropTypes from "prop-types";

const TextField = (props) => {
  const {
    label,
    helperText,
    placeholder,
    InputProps,
    value,
    onChange,
    type,
    error,
    className,
  } = props;

  return (
    <div className={className}>
      <div className="text-secondary text-left font-bold text-sm mb-2">
        {label}
      </div>
      <TextFieldMaterial
        id="outlined-basic"
        variant="outlined"
        color="secondary"
        size="small"
        helperText={error || helperText}
        style={{ width: "100%" }}
        placeholder={placeholder}
        InputProps={InputProps}
        value={value}
        onChange={onChange}
        type={type ? type : "text"}
        error={!!error}
      />
    </div>
  );
};

TextField.propTypes = {
  label: PropTypes.string,
  helperText: PropTypes.string,
  placeholder: PropTypes.string,
  InputProps: PropTypes.object,
  value: PropTypes.any,
  onChange: PropTypes.func,
  type: PropTypes.string,
  error: PropTypes.string,
  className: PropTypes.string,
};

export default TextField;
