import { queryOptions, useQuery } from "@tanstack/react-query";

export const getContactInfo = () => {
  const lastPlates = JSON.parse(
    // Look for new storage and old storage location
    localStorage.getItem("lastPlates") ?? localStorage.getItem("last5Plates")
  );

  return queryOptions({
    queryKey: ["contactInfo"],
    initialData: {
      licensePlate: lastPlates && lastPlates.length > 0 ? lastPlates[0] : "",
      email: localStorage.getItem("lastEmail") ?? "",
      phone: localStorage.getItem("lastPhone") ?? "",
      showEmail: false,
    },
  });
};

export const useContactInfo = () => {
  return useQuery({
    ...getContactInfo(),
  });
};
