import PageLayout from "../../components/Layout/PageLayout";

const SessionExpired = () => {
  const title = "Session Has Expired";
  return (
    <PageLayout title={title}>
      <div className="font-bold">
        We&apos;re sorry, but your session has expired. Please scan a QR code to
        start a new session.
      </div>
    </PageLayout>
  );
};

export default SessionExpired;
