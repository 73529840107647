const Mastercard = () => {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.749969"
        width="19.25"
        height="14"
        rx="1.66705"
        fill="#090B0A"
      />
      <path
        d="M10.3749 10.2151C11.2879 9.44921 11.8667 8.30951 11.8667 7.03683C11.8667 5.76416 11.2879 4.62445 10.3749 3.85852C9.46202 4.62445 8.88318 5.76416 8.88318 7.03683C8.88318 8.30951 9.46202 9.44921 10.3749 10.2151Z"
        fill="#FF5E00"
      />
      <path
        d="M10.3749 10.2151C9.46198 9.44922 8.88314 8.30951 8.88314 7.03684C8.88314 5.76416 9.46198 4.62446 10.3749 3.85853C9.63347 3.23648 8.67176 2.86096 7.62084 2.86096C5.27589 2.86096 3.37494 4.73057 3.37494 7.03684C3.37494 9.34311 5.27589 11.2127 7.62084 11.2127C8.67176 11.2127 9.63347 10.8372 10.3749 10.2151Z"
        fill="#ED0006"
      />
      <path
        d="M10.3749 10.2151C11.2879 9.44922 11.8667 8.30951 11.8667 7.03684C11.8667 5.76416 11.2879 4.62446 10.3749 3.85853C11.1164 3.23648 12.0781 2.86096 13.129 2.86096C15.474 2.86096 17.3749 4.73057 17.3749 7.03684C17.3749 9.34311 15.474 11.2127 13.129 11.2127C12.0781 11.2127 11.1164 10.8372 10.3749 10.2151Z"
        fill="#F9A000"
      />
    </svg>
  );
};

export default Mastercard;
