import { queryOptions, useQuery } from "@tanstack/react-query";

export const getPrepaid = () => {
  return queryOptions({
    queryKey: ["prepaid"],
  });
};

export const usePrepaid = () => {
  return useQuery({
    ...getPrepaid(),
  });
};
