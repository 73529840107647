import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import PageLayout from "../../components/Layout/PageLayout";
import PathConstants from "../../routes/pathConstants";
import Radio from "../../components/Inputs/Radio";
import Motorcycle from "../../assets/Icons/Motorcycle";
import Accessibility from "../../assets/Icons/Accessibility";
import ArrowForward from "../../assets/Icons/ArrowForward";
import { usePrepaid } from "../../hooks/prepaid";
import { postPrepaid } from "../../services/prepaid";
import { useMutation } from "@tanstack/react-query";
import { useParkingPass } from "../../hooks/parking-pass";
import { useNavigate } from "react-router-dom";
import cacheKeys from "../../constants/cache";

const LotMismatchPrepaid = () => {
  const { data: prepaid } = usePrepaid();
  const { data: parkingPass } = useParkingPass();

  const [forceReason, setForceReason] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isRedirecting, setIsRedirecting] = useState(false);
  const navigate = useNavigate();

  const ForceReasons = {
    ADA: "ADA",
    MOTORCYCLE: "Motorcycle",
    PARKED_HERE: "ParkedHere",
  };

  const qrCodeCache = window.sessionStorage.getItem(cacheKeys.qrCode);

  useEffect(() => {
    if (!qrCodeCache) {
      setIsRedirecting(true);
      window.location.replace(PathConstants.ERROR);
    }

    if (!prepaid?.upgradeInfo) {
      setIsRedirecting(true);
      window.location.replace(`${PathConstants.WELCOME}?qr=${qrCodeCache}`);
    }
  }, [qrCodeCache, prepaid]);

  // TODO Save termsdatetime to state and use here
  const mutation = useMutation({
    mutationFn: () =>
      postPrepaid(parkingPass.passBarcode, true, forceReason, Date.now()),
    onSuccess: (data) => {
      prepaid.success = data.success;
      prepaid.failReason = data.failReason;
      prepaid.validTicket = data.validTicket;
      prepaid.receiptID = data.receiptID;
      if (data.success) {
        navigate(PathConstants.CHECKOUT_COMPLETE);
      } else {
        if (data.failReason == "rateLotMismatch") {
          navigate(PathConstants.LOT_MISMATCH_PREPAID);
        }
      }
    },
    onError: (error) => {
      console.error(error);
    },
    onSettled: () => {
      setIsLoading(false);
    },
  });

  const handleOnClickProceed = () => {
    setIsLoading(true);
    mutation.mutate();
  };

  //Do not render anything if we are redirecting
  return isRedirecting ? null : (
    <PageLayout
      withCancel
      isLoading={isLoading}
      title={
        <>
          <span>Alert</span>
          <br />
          <span>Lot Mismatch Detected</span>
        </>
      }
    >
      <div className="pt-2  mx-5">
        It looks like you&apos;re trying to checkout with a pass for the{" "}
        <span className="font-bold">
          {prepaid?.upgradeInfo?.passIsForLotName}
        </span>
        , but your vehicle appears to be located in the{" "}
        <span className="font-bold">
          {prepaid?.upgradeInfo?.parkedInLotName}
        </span>
        .
      </div>
      <div className="pt-6 mx-5">
        If you are confident that you are in the correct location for your pass,
        you may still proceed with the checkout process by selecting one of the
        options below:
      </div>
      <div>
        <Radio
          key="lot"
          label={`I am parked in the ${prepaid?.upgradeInfo?.passIsForLotName}`}
          value={prepaid?.upgradeInfo?.passIsForLotName}
          onChange={() => setForceReason(ForceReasons.PARKED_HERE)}
          checked={forceReason === ForceReasons.PARKED_HERE}
        ></Radio>
        <Radio
          key="ada"
          label="I have an ADA Placard"
          value="ada"
          onChange={() => setForceReason(ForceReasons.ADA)}
          checked={forceReason === ForceReasons.ADA}
          icon={<Accessibility />}
        ></Radio>
        <Radio
          key="motorcycle"
          label="I have a motorcycle"
          value="motorcycle"
          onChange={() => setForceReason(ForceReasons.MOTORCYCLE)}
          checked={forceReason === ForceReasons.MOTORCYCLE}
          icon={<Motorcycle />}
        ></Radio>
      </div>
      <div className="mx-2 my-5">
        <Button
          size="large"
          variant="contained"
          color="primary"
          onClick={handleOnClickProceed}
          style={{ fontWeight: 700, width: "100%" }}
          endIcon={
            <ArrowForward
              fill={
                forceReason === ""
                  ? "rgba(0, 0, 0, 0.26)"
                  : "var(--secondary-color)"
              }
            />
          }
          disabled={forceReason === ""}
        >
          Proceed with Checkout
        </Button>
      </div>
      <div className="mx-2 my-5">
        <Button
          size="large"
          variant="contained"
          color="secondary"
          onClick={() =>
            window.location.replace(
              `${PathConstants.WELCOME}?qr=${qrCodeCache}`
            )
          }
          style={{ fontWeight: 700, width: "100%" }}
        >
          Cancel and Relocate Vehicle
        </Button>
      </div>
    </PageLayout>
  );
};

export default LotMismatchPrepaid;
