export const postPromo = async (promoCode) => {
  const inFlowApiUrl = import.meta.env.VITE_IN_FLOW_API_URL;
  const token = window.sessionStorage.getItem("session_token");
  const headers = { Authorization: `Bearer ${token}` };

  const res = await fetch(`${inFlowApiUrl}/promo`, {
    method: "POST",
    body: JSON.stringify({ promoCode }),
    headers,
  });
  if (!res.ok) throw new Error(res.status);
  return await res.json();
};
