import { useContext } from "react";

import { AppContext } from "../context";

export const useTheme = () => {
  const { theme, setTheme } = useContext(AppContext) ?? {};

  if (!theme) {
    throw new Error("useTheme is not within AppContext scope");
  }
  return { theme, setTheme };
};
