import React from "react";

const Alert = () => {
  return (
    <svg
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="mx-auto"
    >
      <rect width="56" height="56" rx="28" fill="#EFEDE7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5649 31.7163L25.505 21.3202C26.6927 19.541 29.3075 19.5411 30.4952 21.3202L37.4353 31.7163C38.7661 33.7098 37.3371 36.382 34.9402 36.382H21.06C18.663 36.382 17.234 33.7098 18.5649 31.7163ZM28.4049 25.0034H27.5951C27.0071 25.0034 26.546 25.5083 26.5992 26.094L26.9173 29.5906C26.9642 30.1057 27.396 30.5 27.9132 30.5H28.0869C28.604 30.5 29.0359 30.1057 29.0828 29.5906L29.4008 26.094C29.4541 25.5083 28.993 25.0034 28.4049 25.0034ZM28 33C28.5523 33 29 32.5523 29 32C29 31.4477 28.5523 31 28 31C27.4477 31 27 31.4477 27 32C27 32.5523 27.4477 33 28 33Z"
        fill="#B00020"
      />
    </svg>
  );
};

export default Alert;
