import { loadStripe as _loadStripe } from "@stripe/stripe-js";
import * as Sentry from "@sentry/react";

const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const loadStripe = async (publishableKey, options) => {
  const { retryOptions, ...rest } = options || {};
  const { retries = 3, minTimeout = 1000, factor = 2 } = retryOptions || {};

  let lastError;
  for (let attempt = 0; attempt < retries; attempt++) {
    try {
      const stripe = await _loadStripe(publishableKey, rest);
      if (!stripe) {
        throw new Error("Failed to load Stripe");
      }
      return stripe;
    } catch (error) {
      lastError = error;
      if (attempt < retries - 1) {
        const timeout = minTimeout * Math.pow(factor, attempt);
        await delay(timeout);
      } else {
        Sentry.captureException(error);
      }
    }
  }

  throw lastError;
};
