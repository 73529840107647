import { queryOptions, useQuery } from "@tanstack/react-query";

export const getQR = (qr) => {
  return queryOptions({
    queryKey: ["qr"],
    initialData: {
      qr: qr || "",
    },
  });
};

export const useQR = (qr) => {
  return useQuery({
    ...getQR(qr),
  });
};
