import { useNavigate } from "react-router-dom";
import { Button, Divider } from "@mui/material";
import PrivacyAndTerms from "../PrivacyAndTerms";
import BackArrow from "../../../assets/Icons/ArrowBack";
import { useQuery } from "@tanstack/react-query";
import Spinner from "../../../assets/Icons/Spinner";
import Modal from "../Modal";
import Alert from "../../../assets/Icons/Alert";
import PropTypes from "prop-types";
import ParkingIcon from "../../../assets/parking-icon.png";

const PageLayout = (props) => {
  const {
    children,
    title,
    subTitle,
    withCancel,
    cancelRoute,
    isLoading,
    showHelp,
    onCloseHelp,
    networkError,
    networkErrorMessage,
    onCloseNetworkError,
    logoReplacement,
    withoutTerms,
    hideHelpButton,
  } = props;

  const navigate = useNavigate();

  const { data } = useQuery({
    queryKey: ["welcome"],
  });

  const handleOnClickCancel = () => {
    if (cancelRoute) {
      navigate(cancelRoute);
    } else {
      navigate(-1);
    }
  };

  // TODO Uncomment real logo when we get it set up correctly

  return (
    <div className="flex flex-col items-center justify-center">
      <div className="flex flex-col w-full min-h-screen">
        {isLoading ? (
          <div
            className="pt-2 flex flex-col justify-center items-center"
            style={{ height: "calc(100vh - 130px)" }}
          >
            <div className="h-2">
              <Spinner />
            </div>
          </div>
        ) : (
          <>
            <div className="py-4 header-shadow w-full flex flex-row items-center justify-between h-24">
              <div className="w-4/12 flex flex-row justify-start px-2">
                {withCancel && (
                  <Button
                    size="large"
                    onClick={handleOnClickCancel}
                    color="secondary"
                    style={{
                      fontWeight: 700,
                      fontSize: "16px",
                    }}
                  >
                    <BackArrow />
                    <span className="ml-2">Back</span>
                  </Button>
                )}
              </div>
              {logoReplacement ? (
                <h1>{logoReplacement}</h1>
              ) : (
                <div className="flex flex-row items-center justify-center h-full w-4/12">
                  {data?.skin?.logoURL ? (
                    <img className="max-h-16" src={data?.skin?.logoURL} />
                  ) : (
                    <img className="h-16" src={ParkingIcon} />
                  )}
                </div>
              )}
              <div className="w-4/12 px-2"></div>
            </div>
            <div className="flex flex-col h-full grow justify-start">
              <div className="mx-4 mt-8">
                <h1 className="font-bold text-2xl">{title}</h1>
              </div>
              <div className="mx-4 mt-1">
                <h2 style={{ fontWeight: 700, color: "#4e5663" }}>
                  {subTitle}
                </h2>
              </div>
              <div className="mx-auto w-full" style={{ maxWidth: "460px" }}>
                <div className="px-4 mt-8 w-full">{children}</div>
              </div>
            </div>
          </>
        )}
        <PrivacyAndTerms
          showHelp={showHelp}
          onCloseHelp={onCloseHelp}
          withoutTerms={withoutTerms}
          welcomeData={data}
          hideHelpButton={hideHelpButton}
          isLoading={isLoading}
        ></PrivacyAndTerms>
      </div>
      <Modal open={networkError} onClose={onCloseNetworkError}>
        <div>
          <span className="mx-auto">
            <Alert />
            <div className="text-black">
              <div className="text-center mt-4 font-bold text-lg">Warning</div>
              <div className="text-center mt-4"> {networkErrorMessage}</div>
              <div
                className="mt-4"
                style={{ marginLeft: "-1.5rem", marginRight: "-1.5rem" }}
              >
                <Divider />
              </div>
              <div>
                <div className="mt-4 flex justify-end">
                  <Button
                    size="medium"
                    variant="contained"
                    onClick={onCloseNetworkError}
                    color="primary"
                    style={{
                      fontWeight: 700,
                      height: "2.5rem",
                      width: "45%",
                    }}
                  >
                    Close
                  </Button>
                </div>
              </div>
            </div>
          </span>
        </div>
      </Modal>
    </div>
  );
};

PageLayout.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  withCancel: PropTypes.bool,
  cancelRoute: PropTypes.string,
  isLoading: PropTypes.bool,
  showHelp: PropTypes.bool,
  onCloseHelp: PropTypes.func,
  networkError: PropTypes.bool,
  networkErrorMessage: PropTypes.string,
  onCloseNetworkError: PropTypes.func,
  logoReplacement: PropTypes.any,
  withoutTerms: PropTypes.bool,
  hideHelpButton: PropTypes.bool,
};

export default PageLayout;
