export const normalizeCardNumber = (value) => {
  if (!value) {
    return value;
  }
  let numerals = value.replace(/[^\d]/g, "");

  // // special cases for deletion
  // if (value.length - numerals.length === 1) {
  //   numerals = numerals.slice(0, -1);
  // }

  // decorating input
  return numerals.length === 0
    ? ""
    : numerals.length <= 4
    ? `${numerals.substring(0, 4)}`
    : numerals.length <= 8
    ? `${numerals.substring(0, 4)} ${numerals.substring(4, 8)}`
    : numerals.length <= 12
    ? `${numerals.substring(0, 4)} ${numerals.substring(
        4,
        8
      )} ${numerals.substring(8, 12)}`
    : `${numerals.substring(0, 4)} ${numerals.substring(
        4,
        8
      )} ${numerals.substring(8, 12)} ${numerals.substring(12, 16)}`;
};
