import PageLayout from "../../components/Layout/PageLayout";

const PrivacyPolicy = () => {
  return (
    <PageLayout title={`Privacy Policy`} withCancel withoutTerms>
      <div className="text-left">
        <p>Last modified: April 19, 2024</p>
        <p>
          <b>
            <u>Introduction</u>
          </b>
        </p>
        <p>
          Interstate Parking, LLC (and its subsidiaries) and IPC Technology
          Group, LLC (collectively, <b>“Company” </b>or <b>”We”</b>) respect
          your privacy and are committed to protecting it through our compliance
          with this policy.
        </p>
        <p>
          This policy describes the types of information we may collect from you
          or that you may provide when you visit any of our websites, mobile web
          application, and related technology used in providing parking services
          (our <b>“Website”</b>) and our practices for collecting, using,
          maintaining, protecting, and disclosing that information.
        </p>
        <div>
          This policy applies to information we collect:
          <ul>
            <li>On this Website.</li>
            <li>
              In email, text, and other electronic messages between you and this
              Website.
            </li>
          </ul>
        </div>
        <div>
          It does not apply to information collected by:
          <ul>
            <li>
              Us offline or through any other means, including on any other
              website operated by Company or any third party, except as
              addressed below under{" "}
              <b>“California Automated License Plate Recognition Law”</b>; or
            </li>
            <li>
              Any third party, including through any application or content
              (including advertising) that may link to or be accessible from or
              through the Website.
            </li>
          </ul>
        </div>
        <p>
          Please read this policy carefully to understand our policies and
          practices regarding your information and how we will treat it. If you
          do not agree with our policies and practices, your choice is not to
          use our Website. By accessing or using this Website, you agree to this
          privacy policy. This policy may change from time to time (see{" "}
          <b>
            <i>Changes to Our Privacy Policy</i>
          </b>
          ). Your continued use of this Website after we make changes is deemed
          to be acceptance of those changes, so please check the policy
          periodically for updates.
        </p>
        <p>
          <b>
            <u>Children Under the Age of 16</u>
          </b>
        </p>
        <p>
          Our Website is not intended for children under 16 years of age. No one
          under age 16 may provide any information to or on the Website. We do
          not knowingly collect personal information from children under 16. If
          you are under 16, do not use or provide any information on this
          Website or through any of its features, register on the Website, make
          any purchases through the Website, use any of the interactive or
          public comment features of this Website, or provide any information
          about yourself to us, including your name, address, telephone number,
          email address, or any screen name or user name you may use. If we
          learn we have collected or received personal information from a child
          under 16 without verification of parental consent, we will delete that
          information. If you believe we might have any information from or
          about a child under 16, please contact us at
          info@interstateparking.com.
        </p>
        <p>
          <b>
            <u>Information We Collect About You and How We Collect It</u>
          </b>
        </p>
        <div>
          We collect several types of information from and about users of our
          Website, including information:
          <ul>
            <li>
              By which you may be personally identified, such as name, postal
              address, email address, telephone number, any other identifier by
              which you may be contacted online or offline (“
              <b>personal information</b>”); or
            </li>
            <li>
              About your internet connection, the equipment you use to access
              our Website, and usage details.
            </li>
          </ul>
        </div>
        <div>
          {" "}
          We collect this information:
          <ul>
            <li>Directly from you when you provide it to us.</li>
            <li>
              Automatically as you navigate through the site. Information
              collected automatically may include usage details, IP addresses,
              and information collected through cookies and other tracking
              technologies.
            </li>
          </ul>
        </div>
        <div>
          <i>Information You Provide to Us</i>
          <br />
          The information we collect on or through our Website may include:
          <ul>
            <li>
              Information that you provide by filling in forms on our Website.
              This includes information provided at the time of registering to
              use our Website, subscribing to our service, or requesting further
              services. We may also ask you for information when you report a
              problem with our Website.
            </li>
            <li>
              Records and copies of your correspondence (including email
              addresses), if you contact us.
            </li>
            <li>
              Details of transactions you carry out through our Website. You may
              be required to provide financial information before placing an
              order through our Website.
            </li>
          </ul>
        </div>
        <div>
          <i>
            Information We Collect Through Automatic Data Collection
            Technologies
          </i>
          <br />
          As you navigate through and interact with our Website, we may use
          automatic data collection technologies to collect certain information
          about your equipment, browsing actions, and patterns, including:
          <ul>
            <li>
              Details of your visits to our Website, including traffic data,
              location data, logs, and other communication data and the
              resources that you access and use on the Website.
            </li>
            <li>
              Information about your computer and internet connection, including
              your IP address, operating system, and browser type.
            </li>
          </ul>
          The information we collect automatically may include personal
          information. It helps us to improve our Website and to deliver a
          better and more personalized service, including by enabling us to:
          <ul>
            <li>Estimate our audience size and usage patterns.</li>
            <li>
              Store information about your preferences, allowing us to customize
              our Website according to your individual interests.
            </li>
            <li>Speed up your searches.</li>
            <li>Recognize you when you return to our Website.</li>
          </ul>
        </div>
        <div>
          The technologies we use for this automatic data collection may
          include:
          <ul>
            <li>
              <b>Cookies (or browser cookies).</b> A cookie is a small file
              placed on the hard drive of your computer. You may refuse to
              accept browser cookies by activating the appropriate setting on
              your browser. However, if you select this setting you may be
              unable to access certain parts of our Website. Unless you have
              adjusted your browser setting so that it will refuse cookies, our
              system will issue cookies when you direct your browser to our
              Website.
            </li>
            <li>
              <b>Web Beacons.</b> Pages of our Website may contain small
              electronic files known as web beacons (also referred to as clear
              gifs, pixel tags, and single-pixel gifs) that permit we, for
              example, to count users who have visited those pages or and for
              other related website statistics (for example, recording the
              popularity of certain website content and verifying system and
              server integrity).
            </li>
          </ul>
          All of the above categories exclude text messaging originator opt-in
          data and consent; this information will not be shared with any third
          parties.
        </div>
        <div>
          <p>
            <b>
              <u>How We Use Your Information</u>
            </b>
          </p>
          We use information that we collect about you or that you provide to
          us, including any personal information:
          <ul>
            <li>To present our Website and its contents to you.</li>
            <li>
              To provide you with information or services that you request from
              us such as to reserve a parking spot.
            </li>
            <li>To market parking services to you.</li>
            <li>To fulfill any other purpose for which you provide it.</li>
            <li>
              To carry out our obligations and enforce our rights arising from
              any contracts entered into between you and us, including for
              billing and collection.
            </li>
            <li>
              To notify you about changes to our Website or any products or
              services we offer or provide though it.
            </li>
            <li>
              In any other way we may describe when you provide the information.
            </li>
            <li>For any other purpose with your consent.</li>
          </ul>
        </div>
        <div>
          <p>
            <b>
              <u>Disclosure of Your Information</u>
            </b>
          </p>
          We may disclose aggregated information about our users, and
          information that does not identify any individual, without
          restriction.
          <br />
          We may disclose personal information that we collect or you provide as
          described in this privacy policy:
          <ul>
            <li>
              To the parties we provide services for as the owners or lessees of
              the parking facilities accessed and paid for by you using our
              Website.
            </li>
            <li>To our subsidiaries and affiliates.</li>
            <li>
              To contractors, service providers, and other third parties we use
              to support our business.
            </li>
            <li>
              To a buyer or other successor in the event of a merger,
              divestiture, restructuring, reorganization, dissolution, or other
              sale or transfer of some or all of IPC Technology Group, LLC’s
              assets, whether as a going concern or as part of bankruptcy,
              liquidation, or similar proceeding, in which personal information
              held by IPC Technology Group, LLC about our Website users is among
              the assets transferred.
            </li>
            <li>
              For any other purpose disclosed by us when you provide the
              information.
            </li>
            <li>With your consent.</li>
          </ul>
          Any information shared with the types of third parties described above
          is subject to stringent confidentiality agreements, will be subject to
          this privacy policy, including requirements that any shared data only
          be utilized for the same purposes outlined in this privacy policy.
          <br />
          No mobile information will be sold or shared with any third parties or
          affiliates for marketing or promotional purposes.
          <ul>
            <li>
              To comply with any court order, law, or legal process, including
              to respond to any government or regulatory request.
            </li>
            <li>
              To enforce or apply our{" "}
              <a href="https://tapnpark.app/terms-and-conditions">
                terms of use
              </a>{" "}
              and other agreements, including for billing and collection
              purposes.
            </li>
            <li>
              If we believe disclosure is necessary or appropriate to protect
              the rights, property, or safety of IPC Technology Group, LLC, our
              customers, or others.
            </li>
          </ul>
        </div>
        <div>
          <p>
            <b>
              <u>Choices About How We Use and Disclose Your Information</u>
            </b>
          </p>
          We strive to provide you with choices regarding the personal
          information you provide to us. We have created mechanisms to provide
          you with the following control over your information:
          <ul>
            <li>
              <b>Tracking Technologies and Advertising.</b> You can set your
              browser to refuse all or some browser cookies, or to alert you
              when cookies are being sent. If you disable or refuse cookies,
              please note that some parts of this site may then be inaccessible
              or not function properly.
            </li>
          </ul>
          We do not control third parties’ collection or use of your information
          to serve interest-based advertising. However these third parties may
          provide you with ways to choose not to have your information collected
          or used in this way. You can opt out of receiving targeted ads from
          members of the Network Advertising Initiative (<b>“NAI”</b>) on the
          NAI’s website.
        </div>
        <div className="mt-2">
          <p>
            <b>
              <u>Your State Privacy Rights</u>
            </b>
          </p>
          State consumer privacy laws may provide their residents with
          additional rights regarding our use of their personal information.
          California’s “Shine the Light” law (Civil Code Section § 1798.83)
          permits users of our Website that are California residents to request
          certain information regarding our disclosure of personal information
          to third parties for their direct marketing purposes. To make such a
          request, please send an email to info@interstateparking.com or write
          us at: IPC Technology Group, LLC, 710 N Plankinton Avenue, Suite 700
          Milwaukee, Wisconsin 53203.
        </div>
        <div className="mt-2">
          <i>
            <b>California Automated License Plate Recognition Law.</b>
          </i>
          &nbsp; At some California locations, data about parking usage is
          collected using automated license plate recognition (“ALPR”)
          technology to verify parking times and other Service related purposes.
          California Civil Code Section 1798.90.51 requires ALPR providers to
          implement a usage and privacy policy regarding the collection, use,
          maintenance, sharing, and dissemination of ALPR information. To the
          extent we operate an ALPR system in California:
          <ul>
            <li>
              The purpose for using the ALPR system and collecting ALPR is in
              connection with your parking usage to track the entrance and exit
              of vehicles into and out of properties that use the Company for
              its parking facilities
            </li>
            <li>
              We engage third party independent contractors to install and
              maintain ALPR systems for our clients. Our Chief Executive
              Officer, Chief Technology Officer, Director of Project Management,
              Audit &amp; Compliance Director, General Manager and the service
              technicians, customer support and operations management team
              members reporting to the Operations Manager may, under certain
              circumstances, be able to access ALPR information. In addition,
              our third party collections agent may be provided with specific
              ALPR data in connection with unpaid fees for use of the services
              in order to pursue collection of past due debts.
            </li>
            <li>
              All personnel with access to ALPR systems and data will receive
              training on the proper use and handling of such data to ensure
              compliance with applicable laws and customer privacy.
            </li>
            <li>
              We have taken reasonable steps according to industry standards to
              safeguard the information technology utilized in relation to the
              ALPR systems including the implementation of procedures to ensure
              that our systems are free from any components that would permit
              unauthorized access by a third party.
            </li>
            <li>We do not sell, share or transfer any ALPR information.</li>
            <li>
              Our Chief Technology Officer is responsible for the oversight and
              implementation of our ALPR compliance including ensuring the data
              collected and stored is accurate.
            </li>
            <li>
              Upon customer request to verify ALPR information in our
              possession, we will take reasonable measures to do so and to
              confirm such information and, if inaccurate, correct the ALPR
              information.
            </li>
            <li>
              ALPR information will be retained as long as required to confirm
              and verify the legitimacy of charges incurred for the Services
              provided unless a shorter time is required by applicable law. The
              information will be purged when no longer needed for these
              purposes.
            </li>
          </ul>
          ALPR gathered in other states in the course of providing parking
          services is treated in a substantially similar manner as described
          above.
        </div>
        <div className="mt-2">
          <p>
            <b>
              <u>Data Security</u>
            </b>
          </p>
          We have implemented measures designed to secure your personal
          information from accidental loss and from unauthorized access, use,
          alteration, and disclosure.
          <br />
          The safety and security of your information also depends on you. Where
          we have given you (or where you have chosen) a password for access to
          certain parts of our Website, you are responsible for keeping this
          password confidential. We ask you not to share your password with
          anyone.
          <br />
          Unfortunately, the transmission of information via the internet is not
          completely secure. Although we do our best to protect your personal
          information, we cannot guarantee the security of your personal
          information transmitted to our Website. Any transmission of personal
          information is at your own risk. We are not responsible for
          circumvention of any privacy settings or security measures contained
          on the Website.
        </div>
        <div className="mt-2">
          <p>
            <b>
              <u>Changes to Our Privacy Policy</u>
            </b>
          </p>
          It is our policy to post any changes we make to our privacy policy on
          this page. If we make material changes to how we treat our users’
          personal information, we will notify you by email to the email address
          specified in your account. The date the privacy policy was last
          revised is identified at the top of the page. You are responsible for
          ensuring we have an up-to-date active and deliverable email address
          for you, and for periodically visiting our Website and this privacy
          policy to check for any changes.
        </div>
        <div className="mt-2">
          <p>
            <b>
              <u>Contact Information</u>
            </b>
          </p>
          To ask questions or comment about this privacy policy and our privacy
          practices, contact us at:
          <p className="pt-4">IPC Technology Group, LLC</p>
          <p>275 West Wisconsin Avenue, Suite 140</p>
          <p>Milwaukee, Wisconsin 53203</p>
          <p>info@interstateparking.com</p>
        </div>
      </div>
    </PageLayout>
  );
};

export default PrivacyPolicy;
