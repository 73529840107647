import React from "react";

const Receipt = (props) => {
  const { fill } = props;
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-0.000293732 3.59967C-0.000293732 1.77713 1.47717 0.29967 3.29971 0.29967H12.8997C14.7222 0.29967 16.1997 1.77713 16.1997 3.59967V14.8879C16.1997 17.4409 13.0664 18.6666 11.3341 16.7912L11.0905 16.5275C10.803 16.2163 10.3062 16.2327 10.0399 16.5622C9.04139 17.7975 7.15801 17.7975 6.15951 16.5622C5.89317 16.2327 5.39642 16.2163 5.10892 16.5275L4.86528 16.7912C3.13296 18.6666 -0.000293732 17.4409 -0.000293732 14.8879V3.59967ZM3.29971 2.09967C2.47128 2.09967 1.79971 2.77124 1.79971 3.59967V14.8879C1.79971 15.8026 2.92237 16.2418 3.54306 15.5699L3.78671 15.3061C4.81913 14.1885 6.60297 14.2474 7.55941 15.4307C7.83747 15.7747 8.36193 15.7747 8.63999 15.4307C9.59643 14.2474 11.3803 14.1885 12.4127 15.3061L12.6563 15.5699C13.277 16.2418 14.3997 15.8026 14.3997 14.8879V3.59967C14.3997 2.77124 13.7281 2.09967 12.8997 2.09967H3.29971ZM4.2 5.40011C4.2 4.90305 4.60294 4.50011 5.1 4.50011H11.1C11.5971 4.50011 12 4.90305 12 5.40011C12 5.89717 11.5971 6.30011 11.1 6.30011H5.1C4.60294 6.30011 4.2 5.89717 4.2 5.40011ZM5.1 8.10011C4.60294 8.10011 4.2 8.50305 4.2 9.00011C4.2 9.49717 4.60294 9.90011 5.1 9.90011H11.1C11.5971 9.90011 12 9.49717 12 9.00011C12 8.50305 11.5971 8.10011 11.1 8.10011H5.1Z"
        fill={fill ? fill : "var(--secondary-color)"}
      />
    </svg>
  );
};

export default Receipt;
