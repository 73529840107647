import "./App.css";

import { ThemeProvider, createTheme } from "@mui/material/styles";

import CheckboxCheckedIcon from "./assets/Icons/CheckboxCheckedIcon";
import CheckboxUncheckedIcon from "./assets/Icons/CheckboxUncheckedIcon";
import PropTypes from "prop-types";
import { useTheme } from "./hooks/useTheme";

function Theme(props) {
  const { children } = props;

  const { theme: themeConfig } = useTheme();

  const theme = createTheme({
    palette: {
      primary: {
        main: themeConfig?.primary ?? "#E6E6E6",
        contrastText: "#12284a",
      },
      secondary: {
        main: themeConfig?.secondary ?? "#000000",
      },
      neutral: {
        main: themeConfig?.["neutral-light"] ?? "#F7F7F7",
      },
      error: {
        main: themeConfig?.danger ?? "#B0001F",
      },
    },
    typography: {
      MuiCssBaseline: {
        styleOverrides: `@font-face {
          font-family: 'Geist';
          font-style: normal;
          font-weight: 400;
        }`,
      },
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            backgroundColor: themeConfig?.["neutral-light"] ?? "#F7F7F7",
            borderColor: themeConfig?.border ?? "#C4C4C4",
          },
        },
      },
      MuiRadio: {
        styleOverrides: {
          root: {},
        },
      },
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: "0px",
            fontWeight: 700,
            fontSize: "12px",
          },
        },
      },
      MuiCheckbox: {
        defaultProps: {
          icon: <CheckboxUncheckedIcon />,
          checkedIcon: <CheckboxCheckedIcon />,
        },
      },
      MuiModal: {
        styleOverrides: {
          root: {
            marginLeft: "1rem",
            marginRight: "1rem",
            "&:focus": {
              outline: "none",
            },
          },
        },
      },
      MuiBox: {
        styleOverrides: {
          root: {
            "&:focus-visible": {
              outline: "none",
            },
          },
        },
      },
    },
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}

Theme.propTypes = {
  children: PropTypes.node,
};

export default Theme;
