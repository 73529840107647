import { queryOptions, useQuery } from "@tanstack/react-query";
import { postReceipt } from "../services/receipt";

export const getReceiptLink = (receiptId, errorCallback) => {
  return queryOptions({
    queryKey: ["receiptLink"],
    queryFn: async () => {
      const res = await postReceipt(receiptId);
      if (!res && errorCallback) {
        errorCallback();
      }
      return res;
    },
    retry: false,
  });
};

export const useReceiptLink = (receiptId, errorCallback) => {
  return useQuery({
    ...getReceiptLink(receiptId, errorCallback),
  });
};
