import Modal from "../Modal";
import Alert from "../../../assets/Icons/Alert";
import { Button, Divider } from "@mui/material";
import PropTypes from "prop-types";

const ElavonErrorModal = (props) => {
  const { open, onClose, errorLabel, errorDescription } = props;

  return (
    <Modal open={open} onClose={onClose} withClose>
      <div>
        <span className="mx-auto">
          <Alert />
          <div>
            <div className="text-center mt-4 font-bold text-lg">
              {errorLabel}
            </div>
            <div className="text-center mt-4">{errorDescription}</div>
            <div
              className="mt-4"
              style={{ marginLeft: "-1.5rem", marginRight: "-1.5rem" }}
            >
              <Divider />
            </div>
            <div>
              <div className="mt-4 flex justify-center">
                <Button
                  size="medium"
                  variant="contained"
                  onClick={() => onClose()}
                  color="primary"
                  style={{
                    fontWeight: 700,
                    height: "2.5rem",
                    width: "49%",
                  }}
                >
                  Take Me Back!
                </Button>
              </div>
            </div>
          </div>
        </span>
      </div>
    </Modal>
  );
};

ElavonErrorModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  errorLabel: PropTypes.string,
  errorDescription: PropTypes.string,
};

export default ElavonErrorModal;
