import React from "react";

const Motorcycle = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_335_1415)">
        <path
          d="M10.7 11H8.95H12.7H10.7ZM5 19C3.61667 19 2.4375 18.5125 1.4625 17.5375C0.4875 16.5625 0 15.3833 0 14C0 12.6167 0.4875 11.4375 1.4625 10.4625C2.4375 9.4875 3.61667 9 5 9H16.6L14.6 7H11V5H14.575C14.8417 5 15.0958 5.05 15.3375 5.15C15.5792 5.25 15.7917 5.39167 15.975 5.575L19.45 9.05C20.75 9.15 21.8333 9.675 22.7 10.625C23.5667 11.575 24 12.7 24 14C24 15.3833 23.5125 16.5625 22.5375 17.5375C21.5625 18.5125 20.3833 19 19 19C17.6167 19 16.4375 18.5125 15.4625 17.5375C14.4875 16.5625 14 15.3833 14 14C14 13.7 14.0208 13.4042 14.0625 13.1125C14.1042 12.8208 14.1833 12.5333 14.3 12.25L11.55 15H9.9C9.66667 16.1667 9.09167 17.125 8.175 17.875C7.25833 18.625 6.2 19 5 19ZM19 17C19.8333 17 20.5417 16.7083 21.125 16.125C21.7083 15.5417 22 14.8333 22 14C22 13.1667 21.7083 12.4583 21.125 11.875C20.5417 11.2917 19.8333 11 19 11C18.1667 11 17.4583 11.2917 16.875 11.875C16.2917 12.4583 16 13.1667 16 14C16 14.8333 16.2917 15.5417 16.875 16.125C17.4583 16.7083 18.1667 17 19 17ZM5 17C5.63333 17 6.20417 16.8167 6.7125 16.45C7.22083 16.0833 7.58333 15.6 7.8 15H5V13H7.8C7.58333 12.4 7.22083 11.9167 6.7125 11.55C6.20417 11.1833 5.63333 11 5 11C4.16667 11 3.45833 11.2917 2.875 11.875C2.29167 12.4583 2 13.1667 2 14C2 14.8333 2.29167 15.5417 2.875 16.125C3.45833 16.7083 4.16667 17 5 17ZM9.95 13H10.7L12.7 11H8.95C9.2 11.2833 9.40833 11.5917 9.575 11.925C9.74167 12.2583 9.86667 12.6167 9.95 13Z"
          fill="#12284A"
        />
      </g>
      <defs>
        <clipPath id="clip0_335_1415">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Motorcycle;
