export const postPrepaid = async (
  barcode,
  forceCheckout,
  forceReason,
  termsAndConditionsOK
) => {
  const inFlowApiUrl = import.meta.env.VITE_IN_FLOW_API_URL;
  const token = window.sessionStorage.getItem("session_token");
  const headers = { Authorization: `Bearer ${token}` };

  const res = await fetch(`${inFlowApiUrl}/prepaid`, {
    method: "POST",
    body: JSON.stringify({
      barcode,
      forceCheckout,
      forceReason,
      termsAndConditionsOK,
    }),
    headers,
  });
  return await res.json();
};
