import PropTypes from "prop-types";

const CardLayout = (props) => {
  const { title, description, children } = props;

  return (
    <div
      style={{
        backgroundColor: "var(--neutral-light-color)",
        borderRadius: "16px",
        padding: "2rem 1.5rem",
      }}
    >
      <h2
        style={{
          color: "var(--secondary-color)",
          fontWeight: 700,
          fontSize: "20px",
          marginBottom: "1rem",
        }}
      >
        {title}
      </h2>
      <div
        style={{
          color: "var(--secondary-color)",
          fontSize: "14px",
          lineHeight: "17.2px",
          fontWeight: 400,
          maxWidth: "300px",
          margin: "0 auto",
        }}
      >
        {description}
      </div>
      <div>{children}</div>
    </div>
  );
};

CardLayout.propTypes = {
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
};

export default CardLayout;
