import PropTypes from "prop-types";
import { Radio as RadioMaterial } from "@mui/material";

const Radio = (props) => {
  const {
    label,
    amount,
    checked,
    onChange,
    value,
    icon,
    isModalDisplay,
    altAction,
  } = props;

  const checkedBorder =
    "border-primary border-1 rounded radio-shadow items-center";
  const uncheckedBorder = "border-neutral-light border-1 rounded items-center";

  const regularStyle = {
    display: "flex",
    marginTop: "1rem",
    marginBottom: "1rem",
    paddingTop: ".5rem",
    paddingBottom: ".5rem",
  };

  const modalStyle = {
    display: "flex",
    marginTop: ".5rem",
    marginBottom: ".5rem",
  };

  const handleOnClick = (value) => {
    onChange(value);
  };

  return (
    <div
      className={checked ? checkedBorder : uncheckedBorder}
      onClick={() => handleOnClick(value)}
      style={isModalDisplay ? modalStyle : regularStyle}
    >
      <RadioMaterial
        checked={checked}
        label="Test"
        style={{
          backgroundColor: "var(--color-neutral-light)",
          display: "inline-block",
        }}
        sx={{
          "& .MuiSvgIcon-root": {
            fontSize: 28,
          },
        }}
      />
      <div className={altAction ? "flex justify-between w-full" : ""}>
        {icon && <span className="inline-block align-middle mr-3">{icon}</span>}
        <span className="align-middle text-secondary">{label} </span>
        {amount && (
          <span className="align-middle font-bold text-secondary">
            - {amount}
          </span>
        )}
        {altAction}
      </div>
    </div>
  );
};

Radio.propTypes = {
  label: PropTypes.string,
  amount: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
  icon: PropTypes.element,
  isModalDisplay: PropTypes.bool,
  altAction: PropTypes.element,
};

export default Radio;
