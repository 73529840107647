import React from "react";

const Clock = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5 18C6.08172 18 2.5 14.4182 2.5 10C2.5 5.58172 6.08172 2 10.5 2C14.9182 2 18.5 5.58172 18.5 10C18.5 14.4182 14.9182 18 10.5 18ZM11.3 10L11.3 6H9.7V11.6L14.5 11.6V10L11.3 10Z"
        fill="black"
      />
    </svg>
  );
};

export default Clock;
