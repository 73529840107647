export const postEmail = async (receiptId, email) => {
  const inFlowApiUrl = import.meta.env.VITE_IN_FLOW_API_URL;
  const token = window.sessionStorage.getItem("session_token");
  const headers = { Authorization: `Bearer ${token}` };
  const res = await fetch(`${inFlowApiUrl}/email`, {
    method: "POST",
    body: JSON.stringify({ receiptID: receiptId, email }),
    headers,
  });
  if (!res.ok) throw new Error(res.status);
  return await res.json();
};
